import { useRef } from 'react'
import {
  easeIn,
  motion,
  MotionProps,
  MotionStyle,
  MotionValue,
  useInView,
  useMotionTemplate,
  useSpring,
  useTransform,
} from 'framer-motion'
import { twMerge } from 'tailwind-merge'

interface PieProps extends MotionProps {
  className?: string
  value: MotionValue<number>
  steps: number
  enabled?: boolean
  size?: number
}

interface CircleProps {
  radius: number
  value: MotionValue<number>
  className?: string
  style?: MotionStyle
}

function Circle({ radius, className, value, style }: CircleProps) {
  const circ = Math.PI * radius

  const clampedValue = useTransform(value, [0, 1], [0, 1], { clamp: true })
  const dashLength = useTransform(clampedValue, (v) => Math.max(0, Math.min(1, v)) * circ)
  const spaceLength = useTransform(clampedValue, (v) => (1 - v) * circ)
  const strokeDasharray = useMotionTemplate`${dashLength} ${spaceLength}`

  return (
    <motion.circle
      r={radius / 2}
      cx={radius}
      cy={radius}
      className={className}
      style={{
        strokeWidth: radius,
        strokeDasharray,
        ...style,
      }}
    />
  )
}

export function PieProgress({ className, value, steps, size = 100, ...props }: PieProps) {
  const ref = useRef<HTMLDivElement>(null)
  const inView = useInView(ref, { amount: 'some' })

  // Set the pie to 0 if it is below the screen
  const rawProgress = useTransform(value, (v) => (inView || v >= 1 ? v : 0))
  const progress = useSpring(value, { stiffness: 400, damping: 50 }) as MotionValue<number>

  const rawStep = useTransform(rawProgress, (v) => Math.floor(v * steps) / steps)
  const step = useSpring(rawStep, { stiffness: 800, damping: 100 }) as MotionValue<number>

  const rawOpacity = useTransform(progress, (v) => easeIn((v % (1 / steps)) * steps))
  const opacity = useSpring(rawOpacity, { stiffness: 200, damping: 100 }) as MotionValue<number>

  return (
    <motion.div ref={ref} {...props} className="z-10 h-fit w-fit rounded-full">
      <svg
        viewBox={`0 0 ${size} ${size}`}
        className={twMerge(
          'block aspect-square w-fit overflow-visible rounded-full fill-transparent stroke-neutral-850 -rotate-90 [section.dark_&]:stroke-neutral-50',
          className,
        )}
      >
        <Circle radius={size / 2} style={{ opacity }} value={progress} />
        <Circle radius={size / 2} value={step} />
      </svg>
    </motion.div>
  )
}
